.combobox {
    position: relative;
    height: 100%;
}

.comboboxContent {
    position: relative;
    height: 100%;
}

.comboboxInput,
.comboboxInput:hover {
    background: var(--dsp-colors-neutrals-white);
    border: 1px solid var(--dsp-colors-neutrals-silver);
    border-radius: var(--dsp-radius);
    font-family: var(--dsp-font-body);
    font-size: var(--dsp-font-small-size);
    line-height: var(--dsp-font-extraSmall-lineHeight);
    padding: 12px 8px;
    color: var(--dsp-colors-neutrals-gray);
    width: 100%;
    height: 40px;
}

.comboboxInputFull {
    height: 100%;
}

.comboboxWithError {
    border: 1px solid var(--dsp-colors-error-border);
}

.comboboxInput:focus {
    border: 1px solid;
    border-color: var(--dsp-colors-secondary);
    box-shadow: 0 0 6px var(--dsp-colors-action);
}

.comboboxInput::placeholder {
    color: var(--dsp-colors-neutrals-silver);
    font-size: var(--dsp-font-extraSmall-size);
}

.comboboxLabel {
    font-family: var(--dsp-font-main);
    line-height: var(--dsp-font-medium-lineHeight);
    font-size: var(--dsp-font-medium-size);
    color: var(--dsp-colors-neutrals-medium);
    margin-bottom: var(--dsp-space);
    display: block;
}

.comboboxButton {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 0 calc(var(--dsp-space) * 2);
    background: transparent;
    cursor: pointer;
}

.comboboxButton svg {
    width: 16px;
    height: 16px;
    color: var(--dsp-colors-info-border);
    vertical-align: middle;
}

.comboboxButton svg * {
    fill: currentColor;
}

.comboboxOptions {
    list-style: none;
    padding:0;
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    margin: 4px 0;
    background: var(--dsp-colors-neutrals-white);
    border: 1px solid var(--dsp-colors-neutrals-silver);
    border-radius: var(--dsp-radius);
    font-family: var(--dsp-font-body);
    font-size: var(--dsp-font-small-size);
    box-shadow: 0 0 6px var(--dsp-colors-neutrals-silver);
    overflow-y: scroll;
}

.comboboxOption {
    padding: 0 8px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    color: var(--dsp-colors-neutrals-medium);
    line-height:1;
    word-break:break-word;
}

.comboboxOptionCreate {
    cursor:pointer;
    font-weight:500;
}

.comboboxOption:hover,
.comboboxOptionActive {
    background-color: var(--dsp-colors-info-bg);
}
