.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 8px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
    height: inherit;
}

.title {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: rgb(0, 113, 188);
}

.subtitle {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: rgb(81, 111, 144);
}

.editor figure {
    margin:0;
    padding:8px 0;
}

.editor figure img:hover {
    outline:3px solid black;
}