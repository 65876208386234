.buttonWrapper {
    display: inline-block;
    height:100%;
}

.button {
    background: #00538A;
    color: #ddd;
    font-size: 18px;
    border: 0;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
    border-radius: 4px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.button svg {
    fill: #ddd;
}

.button:hover, .button:focus {
    background: #333333;
    outline: 0; /* reset for :focus */
}

.active {
    color: #9DDDF4;
}

.active svg {
    fill: #9DDDF4;
}
