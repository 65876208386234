/* .str-chat-channel.messaging .str-chat__main-panel {
    padding: 0 0 var(--sm-p) var(--xs-p);
} */

.str-chat__channel-list {
    float: right !important;
    height: auto !important;
    /* min-height: 45px !important; */
    width: 100%;
    /* max-height: 387px; */
}

.str-chat-channel {
    max-height: calc(100vh - 64px) !important;
}

.str-chat {
    height: calc(100vh - 64px) !important;
}

.clist .str-chat {
    height: auto !important;
}
.normal_channel_list .str-chat {
    height: 700px !important;
}
@media (max-width:960px){

    #chat-app .clist .str-chat.messaging.light.str-chat-channel-list.str-chat__channel-list.str-chat__channel-list-react{
         position: unset;
         min-height: auto;
    }
    #chat-app >div{
         width: 100% !important;
    }
}

.str-chat-channel-list .str-chat__channel-list-messenger__main {
    overflow-y: unset !important;
    padding: 0 5px 5px 5px !important;
}

.normal_channel_list .str-chat-channel-list .str-chat__channel-list-messenger__main {
    overflow-y: auto !important;
}

.str-chat__channel-list.str-chat__channel-list-react .str-chat__channel-list-messenger-react {
    overflow-y: auto !important;
    padding-bottom: 0px !important;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
    padding-top: 85px !important;
}

.str-chat__channel-search {
    padding: 0 16px !important;
}

.str-chat__avatar-fallback {
    background-color: var(--dsp-colors-secondary) !important;
}

.messaging.str-chat .str-chat__thread {
    max-width: 100%;
}

.str-chat.messaging .str-chat__load-more-button__button {
    /* background-color: #CBD6E2 !important; */
    color: var(--dsp-colors-secondary) !important;
    height: 30px !important;
    border-radius: 4px !important;
    margin-top: 10px;
    font-weight: 500;
}

.close__square-button-container {
    background: #ecebeb;
    border: 1px solid #00000014;
    border-radius: var(--border-radius-sm);
    padding: var(--xxs-p);
    margin: 0 var(--xxs-m);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31px;
    width: 31px;
    cursor: pointer;
}

.close__square-button-container .close__square-button-container-details {
    width: 100%;
}

.str-chat__message--me .str-chat__message-text-inner,
.str-chat__message-simple--me .str-chat__message-text-inner {
    background-color: var(--dsp-colors-secondary) !important;
    color: white !important;
    /* box-shadow: rgba(3, 113, 188, 0.65) 0px 1px 13px 0px !important; */
    box-shadow: rgba(1, 71, 114, 0.9) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px;
    padding: 12px 12px !important;


}

.str-chat__message--me .str-chat__message-simple-text-inner a {
    color: #ffff !important;
}

.str-chat__message-attachment-file--item .str-chat__message-attachment-file--item-text a {
    color: #000 !important;
}

.str-chat__message-text-inner,
.str-chat__message-simple-text-inner {
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important; */
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px;
    background-color: #fff !important;
    padding: 12px 12px !important;
}

.str-chat__date-separator-date {
    font-weight: 500 !important;
    font-family: circe-rounded, serif !important;
}

.str-chat__channel-search input {
    background-color: #f1f1f1 !important;
}

.search {
    border-radius: 50px;
    height: 34px;
    background-color: #EAF0F6 !important;
    padding: 7px 15px;
    overflow-y: hidden;
}

.str-chat__channel-search {
    position: unset !important;
}

.group_channel_list.collapse .str-chat__channel-list,
.normal_channel_list.collapse .str-chat__channel-list {
    height: 0px !important;
    width: 0px !important;
}

.str-chat__message-simple--me .str-chat__message-inner {
    margin-left: 25% !important;
}

.pinned-message {
    background: #e6efff;
}

.str-chat__message-team-pin-indicator {
    font-size: 10px;
    color: #858688;
    padding-left: 72px;
    height: 18px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.pinned-messages__list .pinned-message {
    background: white;
}

.direct-mesage-tag {
    background-color: #D4E4FA;
    padding: 1px 6px 1px 6px;
    display: inline-flex;
    border-radius: 20px;
}

.direct-mesage-tag span {
    font-size: 12px;
    color: #0E4FA6
}

.default-tag {
    background-color: #f9a94d;
    padding: 1px 6px 1px 6px;
    display: inline-flex;
    border-radius: 20px;
    font-size: 12px;
    color: white
}
.new-tag {
    background-color: #0071bc;
    padding: 1px 6px 1px 6px;
    display: inline-flex;
    border-radius: 20px;
    font-size: 12px;
    color: white
}

.direct-mesage-tag-station {
    background-color: white;
    padding: 1px 6px 1px 6px;
    border-left: solid #f9a94d 3px;
    display: inline-flex;
    font-size: 12px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.direct-mesage-tag-schdule {
    background-color: white;
    padding: 1px 6px 1px 6px;
    border-left: solid #7BC2F8 3px;
    display: inline-flex;
    font-size: 12px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.menu-contract .str-chat__channel-list-messenger {
    min-width: 0 !important;
}

.str-chat__avatar {
    margin-right: 0 !important;
}

.drop-down-menu {
    list-style: none;
    position: absolute;
    right: 80px;
    top: 190px;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.drop-down-menu li {
    padding: 15px 30px;
    text-align: center;
    background: #ffff;
    cursor: pointer;
}

.drop-down-menu li:hover {
    background-color: #ecebeb;
}

.str-chat__modal__inner {
    padding: 15px !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
}

/* .thead_container_fullScreen {
    width: 450px;
}

.thead_container_model {
    width: 350px;
} */

/* .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--sm-p);
    background: var(--white);
    box-shadow: 0 7px 9px 0 var(--border), 0 1px 0 0 var(--border);
}

.participants-wrapper {
    display: flex;
    align-items: center;
}

.participant:first-child {
    margin: 0;
}

.participant {
    margin-left: calc(var(--md-m) * -1);
    border-radius: var(--border-radius-round);
    border: 2px solid var(--white);
    padding-right: 0;
}

.reply-count {
    margin-left: var(--sm-m);
    font-weight: var(--font-weight-semi-bold);
}

.close-button {
    width: 24px;
    height: 24px;
}

.left {
    height: 24px;
    width: 3px;
    border-radius: var(--border-radius-sm);
    margin-left: 12px;
    background-color: var(--primary-color);
    transform: rotate(45deg);
    z-index: 1;
}

.right {
    height: 24px;
    width: 3px;
    border-radius: var(--border-radius-sm);
    background-color: var(--primary-color);
    transform: rotate(90deg);
    z-index: 2;
} */

/* header */
/* .header-title {
    padding: 5px 7px;
}

.header-pound {
    color: #006cff;
    font-weight: 800;
    padding-right: 2px;
} */

.str-chat.messaging,
.str-chat.commerce {
    background-color: #fff !important;
}

.str-chat__channel-search {
    background: #fff !important;
}

.str-chat__channel-list-messenger {
    background: #fff !important;
    min-width:100px !important;
}

.str-chat__modal .str-chat__edit-message-form {
    width: 100% !important;
}

/* .str-chat__virtual-list, .str-chat__virtual-list * {
    overflow: unset !important;
  } */

/* .image-gallery-slide {
    top: 50% !important;
    transform: translateY(-50%) !important;
} */

.image-gallery-slides {
    width: 850px;
    height: 600px;
    overflow: auto !important;
}

.image-gallery-content.fullscreen .image-gallery-slides {
    width: 95vw;
    height: 95vh;
    margin: 0 auto;
}

.str-chat__message-attachment--image img {
    height: auto !important;
}

.custome-message-container {
    border-radius: 16px 16px 16px 4px;
    background-color: white;
    border: 1px solid #00000014;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px;
    padding: 12px 12px;
    margin-right: 88px;
    margin-left: unset;
    flex: initial;
    text-align: left;
    max-width: 760px;
    word-wrap: break-word;
    word-break: break-word;
    width: fit-content;
    margin: 7px 5px;
}

.str-chat__message-attachment-card--sticker .str-chat__message-attachment-card--header {
    height: auto !important;
    width: 150px !important;
    display: contents;
}

.str-chat__message-attachment-card--sticker .str-chat__message-attachment-card {
    border: none !important;
}

.str-chat__message-attachment-card--sticker .str-chat__message-attachment-card--content {
    display: none;
}

.str-chat__input-flat-emojiselect {
    left: 10px !important;
    svg {
        height: 26px;
        width: 26px;
    }
}

.deleted-visible{
    .str-chat__message-simple-name{
        display: none;
    }
}
.str-chat__input-flat .str-chat__textarea>textarea {
    padding-left: 110px !important;
}
.input-icons {
    position: absolute;
    left: 80px;
    bottom: 0; 
    top: 0; 
    z-index: 999;
    display: flex;
    align-items: center;
    gap: 2px;
    svg {
        opacity: 0.5; 
        cursor: pointer;
    }
}
.input-icons svg:hover {
    opacity: 1;
}
.giphy-search-bar {
    border: 1px solid;
    margin: 10px 10px 10px 0;
}