.rdg {
    border: none;
}

.rdg.fill-grid {
    height: 100%;
}

.rdg .rdg-header-row, .rdg .rdg-row {
    background: var(--dsp-colors-neutrals-white);
}

@keyframes blinkError {
    0% {
        background-color: var(--dsp-colors-error-bg);
        box-shadow: 0 0 5px var(--dsp-colors-error-shadow);
    }

    35% {
        background-color: var(--dsp-colors-error-bg);
        box-shadow: 0 0 5px var(--dsp-colors-error-shadow);
    }

    100% {
        background-color: var(--dsp-colors-neutrals-white);
        box-shadow: 0 0 0 transparent;
    }
}

@keyframes blinkSuccess {
    0% {
        background-color: var(--dsp-colors-success-bg);
        box-shadow: 0 0 5px var(--dsp-colors-success-shadow);
    }

    35% {
        background-color: var(--dsp-colors-success-bg);
        box-shadow: 0 0 5px var(--dsp-colors-success-shadow);
    }

    100% {
        background-color: var(--dsp-colors-neutrals-white);
        box-shadow: 0 0 0 transparent;
    }
}

.rdg .rdg-row.DELETE-ERROR {
    animation: blinkError 3s;
}

.rdg .rdg-row.INSERT-SUCCESS {
    animation: blinkSuccess 3s;
}

.rdg .rdg-header-row .rdg-cell {
    font-family: var(--dsp-font-main);
    border-bottom: 1px solid var(--dsp-colors-info-border);
    border-right: none;
    padding: 0 8px;
    color: var(--dsp-colors-neutrals-medium);
    font-weight: 400;
}

.rdg.dsp-header-show-text .rdg-header-row .rdg-cell {
    display: flex;
    align-items: end;
    justify-content: Space-between;
    white-space:normal;
    line-height:1;
    padding-bottom: calc(var(--dsp-space) * 2);
    padding-top: calc(var(--dsp-space) * 2);
}

.rdg .rdg-header-row .rdg-cell[aria-sort] {
    color: var(--dsp-colors-secondary);
    font-weight: 600;
}

.rdg .rdg-row .rdg-cell .rdg-group-cell-content {
    font-size: var(--dsp-font-medium-size);
    font-weight: 600;
}

.rdg .rdg-header-row .rdg-cell .rdg-header-sort-cell {
    width: 100%;
}

.rdg .rdg-header-row .rdg-cell .rdg-header-sort-cell span+span {
    align-items: center;
    justify-content: center;
    display: flex;
}


.rdg .rdg-row .rdg-cell {
    border: none;
    color: var(--dsp-colors-neutrals-gray);
    padding: 0 8px;
    border-bottom: 1px dashed var(--dsp-colors-info-shadow);
}

.rdg .rdg-row .rdg-cell.rdg-editor-container {
    padding:0;
    overflow: visible;
    contain: size style;
}

.rdg .rdg-row .rdg-cell.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fix-to-the-right,
.rdg-cell-frozen-last {
    left: 0 !important;
    right: 0 !important;
    overflow: hidden;
    grid-column-start: unset !important;
    width: 80px;
}

.rdg-cell-frozen {
    box-shadow: calc(2px*var(--rdg-sign)) 0 5px -2px hsla(0,0%,53%,.3);
}

.rdg .rdg-row .rdg-cell.upper {
    text-transform: uppercase;
}

.rdg .rdg-row.rdg-group-row .rdg-cell .rdg-group-cell-content {
    color: var(--dsp-colors-secondary);
    font-size: var(--dsp-font-big-size);
}

.rdg .rdg-row:not(.rdg-group-row) > .rdg-cell:not([aria-readonly]) {
    text-decoration:underline;
    color: var(--dsp-colors-secondary);
}

.rdg .rdg-row:last-child .rdg-cell {
    border-bottom: none;
}

.complete {
    background-color: #80808059 !important;
}
.scrollable{
    height: calc(100vh - 107px) !important;
}
.scrollable .fill-grid.dsp-header-show-text{
    height: calc(100% - 40px) !important;
}
/* .rdg-row:nth-last-of-type(1) .rdg-cell:nth-child(2) .combobox_comboboxOptions__hdaMl,
.rdg-row:nth-last-of-type(2) .rdg-cell:nth-child(2) .combobox_comboboxOptions__hdaMl,
.rdg-row:nth-last-of-type(3) .rdg-cell:nth-child(2) .combobox_comboboxOptions__hdaMl,
.rdg-row:nth-last-of-type(4) .rdg-cell:nth-child(2) .combobox_comboboxOptions__hdaMl { */
.rdg-row:nth-last-of-type(1) .rdg-cell:nth-child(2) ul,
.rdg-row:nth-last-of-type(2) .rdg-cell:nth-child(2) ul,
.rdg-row:nth-last-of-type(3) .rdg-cell:nth-child(2) ul,
.rdg-row:nth-last-of-type(4) .rdg-cell:nth-child(2) ul {
    bottom: 100%;
    top: unset;
}