.reference {
    background-color:transparent;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.content {
    display: flex;
    column-gap: calc(var(--dsp-space) * 2);
    margin-right: calc(var(--dsp-space) * 2)
}

.button {
    width:30px;
    height:30px;
    background:#fff;
    box-shadow:0 1px 3px #ccc;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    padding:4px;
}