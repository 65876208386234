.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.color {
    height: 18px;
    width: 18px;
    display: block;
    border-radius: 100%;
    background-color: #000;
    margin-right:4px;
}

.text {
    flex:1;
    text-align: center;
}