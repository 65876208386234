.toolbar {
    border: 1px solid #0071BC;
    background: #00538A;
    border-radius: 4px;
    z-index: 2;
    box-sizing: border-box;
    margin-bottom:8px;
    top: 8px;
    position: relative;
    clear:both;
}

.toolbar > div {
    display: inline-flex;
    align-items: center;
}

.toolbar:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #333;
    border-width: 4px;
    margin-left: -4px;
}

.toolbar:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #111;
    border-width: 6px;
    margin-left: -6px;
}