.content {
    background: var(--dsp-colors-neutrals-white);
    box-shadow: 0 3px 6px var(--dsp-shadow-gray);
    padding: calc(var(--dsp-space) * 2) calc(var(--dsp-space) * 4);
    border-radius: 4px;
}

.hover {
    pointer-events: none;
}

.click {
    pointer-events: auto;
}

.arrow, .arrow:before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

.tooltip {
    background: var(--dsp-colors-neutrals-white);
}


.tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
    right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
    left: -4px;
}
